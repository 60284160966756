import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SearchencodeService } from '../searchencode/searchencode.service';


@Injectable({
  providedIn: 'root'
})
export class MemoriesService {
  private API_URL = environment.API_URL;

  constructor(private http: HttpClient) { }


  // getUserbyId(userId: any): Observable<any> {   
  //   return this.http.get(`${this.API_URL}/ImmortalAdmin/GetUserById?userId=` + userId, {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json",
  //      // Authorization: "Bearer " + localStorage.getItem("token"),
  //     })
  //   })
  // }
  getProfileListWithPagination(paramsReq:any): Observable<any> {
    // debugger;
     let params = new HttpParams({ encoder: new SearchencodeService() })
        .set('userId', paramsReq.userId)
        .set('PageNumber', paramsReq.PageNumber)
        .set('PageSize', paramsReq.PageSize)       
        .set('SortBy', paramsReq.SortBy)
        .set('sortColumn', paramsReq.sortColumn);
        if (paramsReq.searchTerm) {
         params = params.append('searchTerm', paramsReq.searchTerm);
       }
      return this.http.get(`${this.API_URL}/ImmortalAdmin/GetProfileListWithPagination`, {     
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          //  Authorization: "Bearer " + localStorage.getItem("token"),
          }),
          params: params,
        })
    }
  getMemoriesList() {
    // debugger;
   // return this.http.get(`${this.API_URL}/ImmortalAdmin/GetAllUsersProfilesMahial`, {
    return this.http.get(`${this.API_URL}/ImmortalAdmin/GetAllUsersProfilesList`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        // "Authorization":"Bearer " +localStorage.getItem("token") 
      }),
    })
  }

  GetAllProfilePhotoVideoCount(userId: string | number): Observable<any> {
  //  debugger;
    //return this.http.get(`${this.API_URL}/ImmortalAdmin/GetUserById?userId=` + userId, {
    return this.http.get(`${this.API_URL}/ImmortalAdmin/GetUserProfilePhotoVideoByIdmahial?userId=` + userId, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        // "Authorization":"Bearer " +localStorage.getItem("token") 
      }),
    })
  }
  GetAllProfilesCount() {
    //  debugger;
      //return this.http.get(`${this.API_URL}/ImmortalAdmin/GetUserById?userId=` + userId, {
        return this.http.get(`${this.API_URL}/ImmortalAdmin/GetAllProfilesCountDto`, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          // "Authorization":"Bearer " +localStorage.getItem("token") 
        }),
      })
    }

}

// GetAllUserProfiles(): Observable<any>{
//   // debugger;
//    return this.http.get(`${this.API_URL}/ImmortalUser/GetAllUserProfiles?userId=` +userId, {
//      headers: new HttpHeaders({
//        "Content-Type": "application/json",
//        // "Authorization":"Bearer " +localStorage.getItem("token")
//     }),
//    })
//  }

