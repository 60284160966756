import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MemoriesRoutingModule } from './memories-routing.module';
import { AllMemoriesComponent } from './all-memories/all-memories.component';

import { MemoriesComponent } from './memories/memories.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { PagesModule } from '../pages/pages.module';



@NgModule({
  declarations: [
    AllMemoriesComponent,  
    MemoriesComponent,
    
   
  ],
  imports: [
    CommonModule,
    MemoriesRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    PagesModule
  ]
})
export class MemoriesModule { }
