import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersComponent } from './users.component';
import { title } from 'process';
import { InActiveUsersComponent } from './in-active-users/in-active-users.component';
import { AllMemoriesComponent } from '../Memories/all-memories/all-memories.component';

const routes: Routes = [
  {
    path: '',
   // component:UsersComponent,    
      data: {
        title: 'Users'
      },
      children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'users'
        },
        {
          path: 'users',
          component: UsersComponent,
          data: {
            title: 'ActiveUsers',
          },
        },
        {
          path: 'in-active-users',
          component: InActiveUsersComponent,
          data: {
            title: 'InActiveUsers',
          },
        },       
      ]   
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
