<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true">
    <button
      toggle="visible"
      cHeaderToggler
      [cSidebarToggle]="sidebarId"
      class="ps-1"
    >
      <svg cIcon
        name="cilMenu"
        size="lg"
      ></svg>
    </button>
    <c-header-nav class="d-none d-lg-flex me-auto">
      <c-nav-item>
        <a cNavLink routerLink="/dashboard" routerLinkActive="active">
          Dashboard
        </a>
      </c-nav-item>
      <c-nav-item>
        <a cNavLink routerLink="/users" routerLinkActive="active">Users</a>
      </c-nav-item>
      <c-nav-item>
        <a cNavLink routerLink="/memories" routerLinkActive="active">Memories</a>
      </c-nav-item>      
    </c-header-nav>

    <!-- <c-header-nav class="d-none d-lg-flex">
      <c-nav-item>
        <a routerLink="./" cNavLink>
          <svg cIcon name="cilBell" size="lg"></svg>
        </a>
      </c-nav-item>
      <c-nav-item>
        <a routerLink="./" cNavLink>
          <svg cIcon name="cilList" size="lg"></svg>
        </a>
      </c-nav-item>
      <c-nav-item>
        <a routerLink="./" cNavLink>
          <svg cIcon name="cilEnvelopeOpen" size="lg"></svg>
        </a>
      </c-nav-item>
    </c-header-nav> -->
    <c-header-nav class="ms-3">
      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
    </c-header-nav>

  </c-container>
  <c-header-divider></c-header-divider>
  <c-container [fluid]="true">
    <c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
  </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button
      cButton
      color=""
      [caret]="false"
      cDropdownToggle
      class="py-0"
    >
      <c-avatar
        cTextColor="primary"
        shape="rounded-1"
        size="md"
        src="./assets/img/avatars/8.jpg"
        status="success"
      >
      </c-avatar>
    </button>
    <ul cDropdownMenu class="pt-0 pr-5 w-auto">
      <!-- <li>
        <h6 cDropdownHeader class="bg-light fw-semibold py-2">Account</h6>
      </li> -->
      <!-- <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilBell"></svg>
          Updates
          <c-badge class="ms-2 float-end" color="info">
            42
          </c-badge>
        </a>
      </li>-->
     
      <li>
        <h6 cDropdownHeader class="bg-light fw-semibold py-2">Settings</h6>
      </li>
      <li></li>
      <li>
        <a routerLink="/users" cDropdownItem>
          <svg cIcon class="me-2" name="cilUser"></svg>
          Users
        </a>
      </li>
      <li>
        <a routerLink="/memories" cDropdownItem>
         <svg cIcon class="me-2" name="cilSettings"></svg>          
          Memories
        </a>
      </li>
      <!-- <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilCreditCard"></svg>
          Payments
          <c-badge class="ms-2 float-end" color="secondary">
            42
          </c-badge>
        </a>
      </li> -->      
      <li>
        <hr cDropdownDivider/>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilLockLocked"></svg>
          Log Out
        </a>
      </li>
      <li></li>
    </ul>
  </c-dropdown>
</ng-template>

