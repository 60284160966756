import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { configuration } from 'src/app/configuration/configuration';
import { MemoriesService } from 'src/app/services/memories/memories.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-all-memories',
  templateUrl: './all-memories.component.html',
  styleUrls: ['./all-memories.component.scss']
})
export class AllMemoriesComponent implements OnInit {
  isLoad = true;
  memoriesCount : any[] =[];
id: any;


//userId:any;
  userId : any;
  
 

  private API_URL = environment.API_URL;
  public PageSize = configuration.PAGESIZE;
  public PageNumber = configuration.PAGENUMBER;
  public pageSizes = configuration.PAGESIZES;
  selectedPageSize = this.pageSizes[0];
  totalItems: any;
  totalPages: any;
 

  sort = true; 
  column = '1';
  rotate = { 'asc': 'desc', 'desc': '', '': 'asc' };

  direction = 'desc';

  rotate2 = { asc: 'desc', desc: "", "": 'asc' };

  //rotate = { asc: "desc", desc: "", "": "asc" };
  searchQuery = '';
  filterId = 0;
  SortBy: any;
  list: any;
  isSearch = false;
  emptytable = false;

  constructor( private toastr: ToastrService,
    private formBuilder: FormBuilder,

    private httpClient: HttpClient,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    public vcRef: ViewContainerRef,
    //private toastr: ToastrService,
    // private navigationItems: NavigationItems,
    // private toastr: ToastrService,
    private memoriesService: MemoriesService,   
  ) { }

  ngOnInit(): void {
    this.activatedRouter.queryParamMap.subscribe((paramsVal) => {
      
      let tempPageNumber = paramsVal.get("PageNumber");
      let tempPageSize = paramsVal.get("PageSize");
      // let tempFilter = paramsVal.get("filterId");
      let tempSearch = paramsVal.get("SearchTerm");
      if (tempPageNumber !== undefined && tempPageNumber !== null) {
        this.PageNumber = Number(tempPageNumber);
      }
      if (tempPageSize !== undefined && tempPageSize !== null) {
        if (this.pageSizes.indexOf(Number(tempPageSize)) > -1) {
          this.PageSize = Number(tempPageSize);
        }
      }
      if (tempSearch !== undefined && tempSearch !== null) {
        this.searchQuery = tempSearch.toString();
      }

    })
    
   // this.userId = + this.activatedRouter.snapshot.paramMap.get('userId');
   this.activatedRouter.queryParams.subscribe(params => {
    this.userId = +params['userId'];    
   });
   if (!isNaN(this.userId)) {
    // this.GetAllProfilePhotoVideoCount(this.userId); // Call your service method with userId
    this.objmethod(this.userId);
     // Call your service method with userId
  } else {}
  this.objmethod(this.userId);
 // this.getUserId();

  }
  
  objmethod(userId:any) {
  //  debugger;
    let obj = {
      PageNumber: this.PageNumber.toString(),
      PageSize: this.PageSize.toString(),
      SortBy: 'desc',
      sortColumn: '1',
      userId: this.userId,   
      searchTerm: this.searchQuery.trim()
    }
    if (this.searchQuery) {
      obj['searchTerm'] = this.searchQuery.trim()
    }
    this.fetchData(obj);
  }
  fetchData(obj: any) {

    //this.isLoad = true;
    this.memoriesService.getProfileListWithPagination(obj).subscribe((res: any) => {
    // this.isLoad = false;
     // this.list = res.list;
     this.userId = this.userId;    
      this.list = res.list;     
      this.PageSize = res.pageSize;
      this.PageNumber = res.pageNumber;
      this.totalItems = res.totalItems;
      this.totalPages = res.totalPages;
      this.searchQuery = res.searchTerm;
      this.isSearch = false;
      
    })
  }
   // search starts
   keyEvent(event: any) {
    this.searchQuery = (event.target.value).trim();
  }
  handleClear() {
    this.searchQuery = "";
    this.list = false;  
  }

  searchByValue(event: any) {
    this.isSearch = true;
    this.PageNumber = 1;
    this.router.navigate(["/all-memories"], {
      queryParams: {
        userId: this.userId,
        pageNumber: this.PageNumber,
        pageSize: this.PageSize,
        // filterId: this.filterId,
        SortBy: this.direction,
        searchTerm: this.searchQuery.trim(),
      },
    });
    this.objmethod(this.userId);
  }
  // GetAllProfilePhotoVideoCount(userId: number) { 
  //   debugger;  
  // //  this.isLoad = true;
  //  // this.id = id;
  //  // this.firstName = this.firstName
  //   this.memoriesService.GetAllProfilePhotoVideoCount(userId).subscribe((res: any) => {  
  //   this.memoriesCount = res;     
  //   //  this.memoriesCount = res.memoriesCount;     
  //     console.log('profileList:', res)
  //   })   
  // }
// pagination starts
handlePageChange(event: any) {
  this.PageNumber = event;
  this.router.navigate(["/all-memories"], {
    queryParams: {
      userId: this.userId,
      pageNumber: this.PageNumber,
      pageSize: this.PageSize,
      //filterId: this.filterId,
      SortBy: this.direction,
      SearchTerm: this.searchQuery.trim(),
    },
  });

  // let obj = {
  //   pageNumber: this.PageNumber,
  //   pageSize: this.PageSize,
  //  // filterId: this.filterId,
  //   SortBy: this.direction,
  //   sortColumn: this.sortColumn,
  // }
  // if (this.searchQuery) {
  //  // obj['searchTerm'] = this.searchQuery.trim()
  // }
  this.objmethod(this.userId);
}


handlePageSizeChange(event: any) {
  this.PageSize = event.target.value;
  this.PageNumber = 1;
  this.router.navigate(["/all-memories"], {
    queryParams: {
      userId: this.userId,
      pageNumber: this.PageNumber,
      pageSize: this.PageSize,
      //filterId: this.filterId,
      SortBy: this.direction,
      SearchTerm: this.searchQuery.trim(),
    },
  });

  this.objmethod(this.userId);
}

  GetAllProfilePhotoVideoCount(userId: number) {   
    this.isLoad = false;  
    this.memoriesService.GetAllProfilePhotoVideoCount(this.userId).subscribe((res: any) => { 
     this.isLoad = true; 
    this.memoriesCount = res;   
    })   
  }
 
}
