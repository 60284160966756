import { Component, OnInit, ViewEncapsulation, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from "../../services/user/user.service";
import { HttpClient, HttpHeaders, HttpEventType, HttpResponse } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { configuration } from 'src/app/configuration/configuration';
import { Observable } from 'rxjs';
//import { NavigationItems } from 'src/app/Store/navigationitems';
import { HttpParams } from '@angular/common/http';
//import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
// import { ToasterService } from '@coreui/angular';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  cardLoad = true;
  // popupForm = new FormGroup({
  //   isActive: new FormControl('', Validators.required)
  // });

  popupForm: FormGroup = this.formBuilder.group({
  isActive: new FormControl('', Validators.required)
 })
 userscount:any;
  userId: any;
  isActiveStatus: any;
  // isActiveStatus = [
  //   { "id ": "",
  //   "isAvtive ": ""
  // }

  // ]   ;
  // userForm: FormGroup;
  userEnable = true;
  submitted = false;
  modalLoad = false;
  showModal: boolean = false;
  isLoad = true;
  emptytable = false;


  load = true;
  isEdit: boolean = false;

  searchValue: any = '';


  writePermission: any;
  editId: any;
  id: any;
  firstName: any;
  lastName: any;
  email: any;
  mobile: any;
  address1: any;
  address2: any;
  zipCode: any;
  photoLocation: any;
  isActive = null || undefined;

  userRes: any[] = [];
  myModal: any;
  loading = true;
  //users: any = [];
  countryList: any;
  users: any[] = [];
  userDetails: any[] = [];
  userList: any[] = [];
  users1 = [{
    "id": 1609,
    "firstName": "Vidhyalakshmi",
    "lastName": "Subramanian",
    "email": "vidhoo21@yahoo.com",
  }];
  private API_URL = environment.API_URL;
  public PageSize = configuration.PAGESIZE;
  public PageNumber = configuration.PAGENUMBER;
  public pageSizes = configuration.PAGESIZES;
  selectedPageSize = this.pageSizes[0];
  totalItems: any;
  totalPages: any;
  //disableSorting = false;

  sort = true;
  //column = "1";
  //direction = "desc";
  // asc:string;

  //rotate1 = { asc: string; desc: string; "": string; };
  column = '1';
  rotate = { 'asc': 'desc', 'desc': '', '': 'asc' };

  direction = 'desc';

  rotate2 = { asc: 'desc', desc: "", "": 'asc' };

  //rotate = { asc: "desc", desc: "", "": "asc" };
  searchQuery = '';
  filterId = 0;
  SortBy: any;
  list: any;
  isSearch = false;


  tableLoad: boolean = false;
  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,

    private httpClient: HttpClient,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    public vcRef: ViewContainerRef,
    //private toastr: ToastrService,
    // private navigationItems: NavigationItems,
    // private toastr: ToastrService,
    private userService: UserService) {

    // this.popupForm = this.formBuilder.group({       
    //   isActive: ['Yes', Validators.required]
    // })
  }

  ngOnInit(): void {

//this.getActiveUserCount();
    //  this.getList();
    //    this.getList1();
    this.activatedRouter.queryParamMap.subscribe((paramsVal) => {
      let tempPageNumber = paramsVal.get("PageNumber");
      let tempPageSize = paramsVal.get("PageSize");
      // let tempFilter = paramsVal.get("filterId");
      let tempSearch = paramsVal.get("SearchTerm");
      if (tempPageNumber !== undefined && tempPageNumber !== null) {
        this.PageNumber = Number(tempPageNumber);
      }
      if (tempPageSize !== undefined && tempPageSize !== null) {
        if (this.pageSizes.indexOf(Number(tempPageSize)) > -1) {
          this.PageSize = Number(tempPageSize);
        }

      }
      if (tempSearch !== undefined && tempSearch !== null) {
        this.searchQuery = tempSearch.toString();
      }
    })
    this.objmethod();
    this.getActiveUserCount();
  }
  // get fval() {
  //   //return this.popupForm.controls;
  // }
  objmethod() {
    let obj = {
      // pageNumber: this.pageNumber.toString(),
      // pageSize: this.pageSize.toString(),     
      // SortBy: 'desc',
      // sortColumn: '1',
      // "pageNumber": this.pageNumber,
      // "pageSize": this.pageSize,
      // "searchTerm": this.searchValue.trim()
      PageNumber: this.PageNumber.toString(),
      PageSize: this.PageSize.toString(),
      SortBy: 'desc',
      sortColumn: '3',
      searchTerm: this.searchQuery.trim()
    }
    if (this.searchQuery) {
      obj['searchTerm'] = this.searchQuery.trim()
    }
    this.fetchData(obj);
  }
  // if (this.searchQuery) {
  //   obj['searchTerm'] = this.searchQuery.trim()
  // }

  // public fetchData1(){
  //   this.userService.getUserListWithPagination(this.objmethod).subscribe((res: any) => {
  //     this.userDetails = res.data;
  //     console.log('fatchdata:', this.userDetails)
  //   })
  // }
  fetchData(obj: any) {
    this.isLoad = true;
    this.userService.getUserListWithPagination(obj).subscribe((res: any) => {
      this.isLoad = false;
      this.list = res.list;
      this.PageSize = res.pageSize;
      this.PageNumber = res.pageNumber;
      this.totalItems = res.totalItems;
      this.totalPages = res.totalPages;
      this.searchQuery = res.searchTerm;
      this.isSearch = false;
      if (this.list.length == 0) {
        this.emptytable = true;
      } else {
        this.emptytable == false;
      }
    })
  }


  getList() {
    //debugger;
    this.showloader();
    this.isLoad = false;

    this.userService.getUserList().subscribe((res: any) => {
      this.tableLoad = false;
      this.hideloader();
      this.users = res.users;
      // this.users = res.list;
      console.log('list:', res)
    })
    // (error) => {
    //   this.isLoad = false
    // });
  }
get vstatus(){
return this.popupForm.get("isActive");
}
  edit(data: any, id: any) {
    // debugger;
    this.showloader();
    this.showModal = true;
    this.isEdit = true;
    this.load = false;
    //this.tableLoad = true;
    this.id = id;
    this.firstName = this.firstName;
    this.lastName = this.lastName;
    this.email = this.email;
    this.mobile = this.mobile;
    this.address1 = this.address1;
    this.address2 = this.address2;
    this.zipCode = this.zipCode;
    this.isActive = this.isActive;
    this.photoLocation = this.photoLocation;
    // this.load = true;
    this.userService.getUserbyId(id)
      .subscribe((res: any) => {
        if (res) {
          // this.load = false;          
          this.firstName = res.firstName;
          this.lastName = res.lastName;
          this.email = res.email;
          this.mobile = res.mobile;
          this.address1 = res.address1;
          this.address2 = res.address2;
          this.zipCode = res.zipCode;
          this.isActive = res.isActive;
          this.photoLocation = res.photoLocation;
          
        }

        //    let userRes = res[0];
        //      this.id = res.id;
        //     this.firstName = res.firstName;
        // console.log('EDIT:', res.id)   
        // console.log('EDIT:', res.firstName)   


        console.log('EDIT:', res)
      });

  }
  // pagination starts
  handlePageChange(event: any) {
    this.PageNumber = event;
    this.router.navigate(["/users"], {
      queryParams: {
        pageNumber: this.PageNumber,
        pageSize: this.PageSize,
        //filterId: this.filterId,
        SortBy: this.direction,
        SearchTerm: this.searchQuery.trim(),
      },
    });

    // let obj = {
    //   pageNumber: this.PageNumber,
    //   pageSize: this.PageSize,
    //  // filterId: this.filterId,
    //   SortBy: this.direction,
    //   sortColumn: this.sortColumn,
    // }
    // if (this.searchQuery) {
    //  // obj['searchTerm'] = this.searchQuery.trim()
    // }
    this.objmethod();
  }


  handlePageSizeChange(event: any) {
    this.PageSize = event.target.value;
    this.PageNumber = 1;
    this.router.navigate(["/users"], {
      queryParams: {
        pageNumber: this.PageNumber,
        pageSize: this.PageSize,
        //filterId: this.filterId,
        SortBy: this.direction,
        SearchTerm: this.searchQuery.trim(),
      },
    });

    this.objmethod();
  }
  // pagination ends


  // search starts
  keyEvent(event: any) {
    this.searchQuery = (event.target.value).trim();
  }
  handleClear() {
    this.searchQuery = "";
    this.emptytable = false;
    // this.objmethod();
  }
  searchByValue(event: any) {
    this.isSearch = true;
    this.PageNumber = 1;
    this.router.navigate(["/users"], {
      queryParams: {
        pageNumber: this.PageNumber,
        pageSize: this.PageSize,
        // filterId: this.filterId,
        SortBy: this.direction,
        searchTerm: this.searchQuery.trim(),
      },
    });
    this.objmethod();
  }

  onSort(column: any) {
    //this.direction = this.rotate[this.direction];
    const rotate: { [key: string]: string } = { "": "asc", "asc": "desc", "desc": "" };

    this.direction = rotate[this.direction];
    // this.direction = this.rotate.desc;
    //this.direction = this.rotate2[this.direction];
    // debugger;
    this.column = this.direction == "" ? "1" : column;
    let obj = {
      PageNumber: this.PageNumber.toString(),
      PageSize: this.PageSize.toString(),
      //SortBy: 'desc',
      SortBy: this.direction,
      sortColumn: '1',
      searchTerm: this.searchQuery.trim()
    }
    if (this.searchQuery) {
      obj['searchTerm'] = this.searchQuery ? this.searchQuery.trim() : ""
    }
    // if (this.searchQuery) {
    //   obj['SearchTerm'] = this.searchQuery.trim()
    // }

    this.fetchData(obj);
    console.log('Fetch:', obj)
  }
  //search ends

  hide() {
    this.showModal = false;
    this.popupForm.reset();
  }
  //   saveUser()
  //   {
  //   let item = {
  //     "id": this.id,
  //    // "isActive": this.isActive.value == "Yes" ? true : false
  //    "firstName": this.firstName,
  //    "lastName": this.lastName,
  //    "email": this.email,
  //    "mobile": this.mobile,
  //    "address1": this.address1,
  //    "address2":this.address2,
  //    "zipCode": this.zipCode,
  //    "photoLocation": this.photoLocation,
  //    //"isActive": this.popupForm.value == "Yes" ? true : false,
  //    "isActive": this.isActive

  //   }

  //   this.isActiveStatus = item;


  //   let obj = {
  //     id: this.id,
  //     zipCode: this.zipCode
  //     //firstName: this.firstName,
  //   }
  //   console.log('Updating user status with:', obj);
  //   this.userService.updateUserStatus(obj.id, this.isActiveStatus).subscribe(res => {
  //     // if (res) {
  //     //  // this.load = false;          
  //     //   this.firstName = res.firstName;
  //     //   this.lastName = res.lastName;
  //     //   this.email = res.email;
  //     //   this.zipCode = res.zipCode;
  //     //   this.isActive = res.isActive;

  //     // }  
  //     this.showModal = false;
  //     this.submitted = false;
  //     console.log('SAVE:', obj)
  //     console.log('SAVE:', obj)

  //  // this.getList();
  //  this.objmethod();
  //  this.showSuccess();
  //   //this.toastr.success('Update successful!', 'Success');
  //   //this.showCreated();
  //   },
  //   // err => {
  //   //   console.error('Error updating user status:', err);
  //   // }
  //   )
  //   }

  saveUser() {
   debugger;
    let item = {
      "id": this.id,
      // "isActive": this.isActive.value == "Yes" ? true : false
      "firstName": this.firstName,
      "lastName": this.lastName,
      "email": this.email,
      "mobile": this.mobile,
      "address1": this.address1,
      "address2": this.address2,
      "zipCode": this.zipCode,
      "photoLocation": this.photoLocation,
     
      //"isActive": this.popupForm.value == "Yes" ? true : false,
     // "isActive": this.isActive.popupForm.value == false
      "isActive": this.isActive == "false" ? true : false
    }

    this.isActiveStatus = item;


    let obj = {
      id: this.id,
     isActive: this.isActive
      //firstName: this.firstName,
    }
    console.log('Updating user status with:', obj);
    this.userService.updateUserStatus(obj.id, this.isActiveStatus).subscribe(res => {

        this.showModal = false;
        this.submitted = false;
        console.log('SAVE:', obj)
        console.log('SAVE:', obj)

        // this.getList();
        this.objmethod();
        this.getActiveUserCount();
        this.showSuccess();
    this.popupForm.reset();

      //this.toastr.success('Update successful!', 'Success');
      //this.showCreated();
    },
      // err => {
      //   console.error('Error updating user status:', err);
      // }
    )
  }
  getActiveUserCount() { 
    this.userService.getActiveUserCount().subscribe((res: any) => {    
      this.userscount = res;  
      this.objmethod();    
    })
  }
  public showloader() {
    this.loading = true;
  }
  public hideloader() {
    this.loading = false;
  }
  delete() {

  }


  //working good
  getList1() {
    //debugger;
    this.showloader();
    this.userService.getUserList().subscribe((res: any) => {
      this.hideloader();
      this.users = res.users;
      console.log('list:', res)
    })
  }
  showCreated() {
    //this.toastr.success('Successfully Updated user data', "", {
    //   timeOut: 1500
    // });
  }
  showSuccess() {
    this.toastr.success('User status Updated Successfully', 'Success')
  }

}
