
<div class="animated fadeIn">
  <div class="row">
    <div class="col-lg-12">
      <div class="card text-black bg-white mb-3">
        <div class="card-body">
          <h5 class="card-title">Memories</h5>
          <div class="text-black display-4 text-end">
            <p>{{memoryCount.activeCount}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

<!-- <div *ngIf="isLoad" class="animated fadeIn">  
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <span><i class="icon-user-following"></i>Memories</span>
          </div>
          <div class="card-body">
            <div
              class="table-responsive{-sm|-md|-lg|-xl} table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl">
              <table class="table table-striped table-hover">
                <thead class="table-primary">
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Memories</th>
                    <th>Last Login<th>  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="6" class="text-center mt-3">
                      <div class="sk-wandering-cubes">
                        <div class="sk-cube sk-cube1"></div>
                        <div class="sk-cube sk-cube2"></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div *ngIf="isLoad" class="animated fadeIn">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <span><i class="icon-user-following"></i>Memories</span>
          </div>
          <div class="card-body">
            <div
              class="table-responsive{-sm|-md|-lg|-xl} table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl">
              <table class="table table-striped table-hover">
                <thead class="table-primary">
                  <tr>
                    <th>User Name</th>
                    <th>Email</th>
                    <th>Memories</th>
                    <th>Last Login</th>  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="6" class="text-center mt-3">
                      <div class="sk-wandering-cubes">
                        <div class="sk-cube sk-cube1"></div>
                        <div class="sk-cube sk-cube2"></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="animated fadeIn" *ngIf="!isLoad">
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-3">
          <div class="card-header">
            <span><i class="fa fa-card-clip"></i> Memories</span>
           
          </div>
          <div class="card-body">
            <div class="row mb-3">     
                <!-- <form class="col-lg-12 col-md-12 col-sm-12 input-group mb-4">
                  <input class="form-control" type="text" name="search" (keyup)="keyEvent($event)"
                      (keydown)="keyEvent($event)" placeholder="Search by FirstName, LastName"
                      autocomplete="off" value={{searchQuery}}>
                  <div class="input-group-prepend">
                      <button class="input-group-text btn btn-wave btn-light" type="submit"
                          [disabled]="searchQuery==''||searchQuery==undefined" (click)="searchByValue($event)"><i
                              class="fa fa-search"></i>
                      </button>
                  </div>
                  <div class="input-group-prepend">
                      <button (click)="handleClear()" class="input-group-text btn btn-wave btn-light"
                          [disabled]="searchQuery==''||searchQuery==undefined" (click)="searchByValue($event)"><i
                              class="fa fa-close"></i>
                      </button>
                  </div>
              </form> -->
            </div>
              <!-- <div class="row">
                  <div class="col-lg-8 col-md-6 col-sm-12">
                    <pagination-controls previousLabel="Prev" nextLabel="Next" [responsive]="true"
                      (pageChange)="handlePageChange($event)"></pagination-controls>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 pull-right">
                    <span class="float-right">
                      Items Per Page
                      <select (change)="handlePageSizeChange($event)">
                        <option *ngFor="let size of pageSizes" [ngValue]="size" [selected]="size == PageSize">
                          {{ size }}
                        </option>
                      </select>
                    </span>
                  </div>
                </div> -->
            <div
              class="table-responsive{-sm|-md|-lg|-xl} table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl">
              <table class="table table-striped table-hover">
                <thead class="table-primary">
                  <tr>
                    <th>User Name</th>
                    <th>Email</th>
                    <th>Memories</th>
                    <th>Last Login</th>  
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of memories">
                   <th>{{data.firstName}} {{data.lastName}}</th>
                  <th>{{data.email}}</th>
                  <!-- <th><a  [routerLink]="['/all-memories', data.userId]" >{{data.profileCount}}</a></th> -->
                 <th><a [routerLink]="['/all-memories']" [queryParams]="{ userId: data.userId }">{{data.profileCount}}</a></th> 
                    <th>{{data.updatedDate | date :'dd MMM yyyy, HH:mm\'hrs\'' }}</th>
                  </tr>
                  <tr *ngIf="">
                    <td class="text-center mt-3" colspan="6">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div class="row">
              <div class="col-lg-8 col-md-6 col-sm-12">
                <pagination-controls previousLabel="Prev" nextLabel="Next" [responsive]="true"
                  (pageChange)="handlePageChange($event)"></pagination-controls>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12 pull-right">
                <span class="float-right">
                  Items per Page
                  <select (change)="handlePageSizeChange($event)">
                    <option *ngFor="let size of pageSizes" [ngValue]="size" [selected]="size == PageSize">
                      {{ size }}
                    </option>
                  </select>
                </span>
              </div>
            </div> -->         
          </div>
        </div>
      </div>
    </div>
  </div>

</div> 
<!-- memories.component.html -->
<!-- <div *ngIf="memories && memories.length > 0; else noMemories">
    <div *ngFor="let data1 of memories">
      <p *ngIf="data1.user">User First Name: {{ data1.user.firstName }}</p>
      <p *ngIf="data1.user">User Last Name: {{ data1.user.lastName }}</p>
      <p *ngIf="data1.profile">Profile First Name: {{ data1.profile.profileType }}</p>
    
     
    </div>
  </div>
  <ng-template #noMemories>
    <p>No memories found in Memories1.</p>
  </ng-template> -->
  

  <!-- <div
  class="table-responsive{-sm|-md|-lg|-xl} table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl">
  <table class="table table-striped table-hover">
    <thead class="table-primary">
      <tr>
        <th>UserName</th>
        <th>Email</th>
        <th>Created Date</th>
        <th>Modified Date<th>                    
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of memories; let i = index;">
        <td>{{data.user.firstName}} </td>
        <td>{{data.profile.age}} </td>
        <td>{{data.createdDate}} </td>
        <td>{{data.updatedDate}}</td>
        <td>
            <td>
              <button type="button" class="btn btn-primary btn-sm">View</button>
            </td>
           
      </tr>
      <tr *ngIf="">
        <td class="text-center mt-3" colspan="6">No data found</td>
      </tr>
    </tbody>
  </table>
</div> -->