import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllMemoriesComponent } from './all-memories/all-memories.component';
import { MemoriesComponent } from './memories/memories.component';
import { AllProfileMemoryComponent } from './all-profile-memory/all-profile-memory.component';
import { DefaultLayoutComponent } from 'src/app/containers';
import { BreadcrumbComponent, BreadcrumbItemComponent } from '@coreui/angular';

const routes: Routes = [ 
  {
    path: '',
    data: {
      title: 'Memories'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'memories'
      },
      {
        path: 'memories',
        component: MemoriesComponent,
        data: {
          title: 'Memories'
        }
      },
      // {
      //     path: 'all-memories',
      //     component: AllMemoriesComponent,
      //     data: {
      //       title: 'All Memories'
      //     }
      //   }
      // {
      //   path: 'memories',
      //   component: AllMemoriesComponent,
      //   data: {
      //     title: 'All-Memories'
      //   }
      // },
      // {
      //   path: 'badges',
      //   component: BadgesComponent,
      //   data: {
      //     title: 'Badges'
      //   }
      // },
      // {
      //   path: 'modal',
      //   component: ModalsComponent,
      //   data: {
      //     title: 'Modal'
      //   }
      // },
      // {
      //   path: 'toasts',
      //   component: ToastersComponent,
      //   data: {
      //     title: 'Toasts'
      //   }
      // }
    ]
  },
  // {
  //   path: '',
  //   component: MemoriesComponent,
  //   data: {
  //     title: 'Home'
  //   },
  //   children: [
  //     {       
  //         path: 'memories',
  //         component: AllMemoriesComponent,
  //         data: {
  //           title: 'All-Memories'
  //         }
  //     },
  //     {       
  //       path: 'memories',
  //       component: AllMemoriesComponent,
  //       data: {
  //         title: 'All-Memories'
  //       }
  //   }

   // ]
 // },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MemoriesRoutingModule { }
