<!--<c-footer>-->
  <!-- <div>
    <a href="https://coreui.io/pro/angular/" target="_blank">CoreUI</a>
    <span> &copy; 2022 creativeLabs</span>
  </div>
  <div class="ms-auto">
    Powered by
    <a href="https://coreui.io/pro/angular" target="_blank">
      <span> CoreUI for Angular</span>
    </a>
  </div> -->
  <div class="ms-auto">
    &copy; 2024 All rights reserved.
      <span> Immortal</span>
    
  </div> 
<!--</c-footer>-->
