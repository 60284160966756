<div class="animated fadeIn">
  <!-- <div class="row">
    <div class="col-lg-12">
      <div class="card text-black bg-white mb-3">
        <div class="card-body">
          <h5 class="card-title">Memories</h5>
          <div class="text-black display-4 text-end">
            <div *ngIf="memoriesCount">
             
            </div>            
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div *ngIf="isLoad" class="animated fadeIn">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <span><i class="icon-user-following"></i>Memory Details</span>
          
          </div>
          <div class="card-body">

            <div class="row mb-3">             
              <form class="col-lg-12 col-md-12 col-sm-12 input-group mb-4">
                <input class="form-control" type="text" name="search" (keyup)="keyEvent($event)" 
                  (keydown)="keyEvent($event)" placeholder="Search by FirstName, LastName" autocomplete="off"
                  value={{searchQuery}}>
                <div class="input-group-prepend">
                  <button class="input-group-text btn btn-wave btn-light" type="submit"
                    [disabled]="searchQuery==''||searchQuery==undefined" (click)="searchByValue($event)"><i
                      class="fa fa-search"></i>
                  </button>
                </div>
                <div class="input-group-prepend">
                  <button (click)="handleClear()" class="input-group-text btn btn-wave btn-light"
                    [disabled]="searchQuery==''||searchQuery==undefined" (click)="searchByValue($event)"><i
                      class="fa fa-close"></i>
                  </button>
                </div>
              </form>
            </div>
            <div class="row">
              <div class="col-lg-10 col-md-6 col-sm-12">
                <pagination-controls previousLabel="Prev" nextLabel="Next" [responsive]="true"
                  (pageChange)="handlePageChange($event)"></pagination-controls>
              </div>
              <!-- <div>{{totalItems}}</div> -->
              <div class="col-lg-2 col-md-6 col-sm-12 pull-right">
                <span class="float-right">
                  Items per Page
                  <select (change)="handlePageSizeChange($event)">
                    <option *ngFor="let size of pageSizes" [ngValue]="size" [selected]="size == PageSize">
                      {{ size }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
            <div *ngIf="list"
              class="table-responsive{-sm|-md|-lg|-xl} table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl">
              <table class="table table-striped table-hover" >
                <thead class="table-primary">
                  <tr>
                    <th>Memory Name</th>
                    <th>Images</th>
                    <th>Videos</th>
                    <th>Last Creation</th>
                    <th>Last Modified</th>                   
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of list | paginate: { itemsPerPage: PageSize, currentPage: PageNumber, totalItems: totalItems }">
                    <td>{{ data.firstName }} {{ data.lastName }}</td>
                    <td>{{ data.photoCount }}</td>
                    <td>{{ data.videoCount }}</td>
                    <td>{{ data.createdDate | date :'dd MMM yyyy, HH:mm\'hrs\'' }}</td>
                    <td>{{ data.updatedDate | date :'dd MMM yyyy, HH:mm\'hrs\'' }}</td>
                   
                  </tr>
                </tbody>
              </table>
              <div *ngIf="list.length === 0">
                <p class="text-center mt-3">No data found</p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-10 col-md-6 col-sm-12">
                <pagination-controls previousLabel="Prev" nextLabel="Next" [responsive]="true"
                  (pageChange)="handlePageChange($event)"></pagination-controls>
              </div>
              <div class="col-lg-2 col-md-6 col-sm-12 pull-right">
                <span class="float-right">
                  Items per Page
                  <select (change)="handlePageSizeChange($event)">
                    <option *ngFor="let size of pageSizes" [ngValue]="size" [selected]="size == PageSize">
                      {{ size }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="animated fadeIn" *ngIf="!isLoad">
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-3">
          <div class="card-header">
            <span><i class="fa fa-user-times"></i> Memory Details</span>
          </div>
          <div class="card-body">
            <p class="text-center mt-3">Loading...</p>
            <!-- Loading indicator or message while data is being fetched -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>