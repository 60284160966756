import { Component, OnInit, ViewEncapsulation , ViewContainerRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators , FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from "../../../services/user/user.service";
import { HttpClient, HttpHeaders, HttpEventType, HttpResponse} from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { configuration } from 'src/app/configuration/configuration';
import { Observable } from 'rxjs';
//import { NavigationItems } from 'src/app/Store/navigationitems';
import {  HttpParams } from '@angular/common/http';
//import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
// import { ToasterService } from '@coreui/angular';
import { ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-in-active-users',
  templateUrl: './in-active-users.component.html',
  styleUrls: ['./in-active-users.component.scss']
})
export class InActiveUsersComponent implements OnInit {
  inactiveuserscount:any;
  popupForm1 = new FormGroup({
    isActive: new FormControl('', Validators.required)
  });

  load = true;
  isEdit: boolean = false;
  isLoad = true;
  users: any;
  emptytable = false;

  myModal: any;
  showModal: boolean= false;
  id: any;
  firstName: any;
  lastName: any;
  email: any;
  mobile: any;
  address1: any;
  address2: any;
  zipCode: any;
  isActive: any;
  photoLocation: any;
  loading = true;
  private API_URL = environment.API_URL;
  public PageSize = configuration.PAGESIZE;
  public PageNumber = configuration.PAGENUMBER;
  public pageSizes = configuration.PAGESIZES;
  selectedPageSize = this.pageSizes[0];
  totalItems: any;
  totalPages: any;
  sort = true; 
  column = '1';
  searchQuery = '';
  list: any;
  isSearch = false;
  isActiveStatus : any;
	submitted = false;
  direction = 'desc';

  constructor(private toastr: ToastrService,
    private formBuilder: FormBuilder,
   
    private httpClient: HttpClient,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    public vcRef: ViewContainerRef,
    //private toastr: ToastrService,
    // private navigationItems: NavigationItems,
    // private toastr: ToastrService,
    private userService: UserService) {
     
     }

  ngOnInit(): void {
    this.activatedRouter.queryParamMap.subscribe((paramsVal) => {
      let tempPageNumber = paramsVal.get("PageNumber");
      let tempPageSize = paramsVal.get("PageSize");
      // let tempFilter = paramsVal.get("filterId");
      let tempSearch = paramsVal.get("SearchTerm");
      if (tempPageNumber !== undefined && tempPageNumber !== null) {
        this.PageNumber = Number(tempPageNumber);
      }
      if (tempPageSize !== undefined && tempPageSize !== null) {
        if (this.pageSizes.indexOf(Number(tempPageSize)) > -1) {
          this.PageSize = Number(tempPageSize);
        }
      }
      if (tempSearch !== undefined && tempSearch !== null) {
        this.searchQuery = tempSearch.toString();
      }
    })
    this.objmethod();
    //this.getInactiveUserList();
    this.getInActiveUserCount();
  }
  objmethod() {
    let obj = {      
      PageNumber: this.PageNumber.toString(),
      PageSize: this.PageSize.toString(),
      SortBy: 'desc',
      sortColumn: '1',
      searchTerm: this.searchQuery.trim()
    }
    if (this.searchQuery) {
      obj['searchTerm'] = this.searchQuery.trim()
    }
    this.fetchData(obj);
  }
  fetchData(obj: any) {
    this.isLoad = true;
    this.userService.getInactiveUserListWithPagination(obj).subscribe((res: any) => {
      this.isLoad = false;
      this.list = res.list;
      this.PageSize = res.pageSize;
      this.PageNumber = res.pageNumber;
      this.totalItems = res.totalItems;
      this.totalPages = res.totalPages;
      this.searchQuery = res.searchTerm;
      this.isSearch = false;
      if (this.list.length == 0) {
        this.emptytable = true;
      } else {
        this.emptytable == false;
      }
    })
  }
// pagination starts
handlePageChange(event: any) { 
  this.PageNumber = event;
  this.router.navigate(["/users/in-active-users"], {
    queryParams: {
      pageNumber: this.PageNumber,
      pageSize: this.PageSize,
      //filterId: this.filterId,
      SortBy: this.direction,
      SearchTerm: this.searchQuery.trim(),
    },
  });
  this.objmethod();
}


handlePageSizeChange(event: any) {
  this.PageSize = event.target.value;
  this.PageNumber = 1;
  this.router.navigate(["users/in-active-users"], {
    queryParams: {
      pageNumber: this.PageNumber,
      pageSize: this.PageSize,
      //filterId: this.filterId,
      SortBy: this.direction,
      SearchTerm: this.searchQuery.trim(),
    },
  });

  this.objmethod();
}
// pagination ends
// search starts

keyEvent(event: any) {
  this.searchQuery = (event.target.value).trim();
}
handleClear() {
  this.searchQuery = "";
  this.emptytable = false;
  // this.objmethod();
}
searchByValue(event: any) {
  this.isSearch = true;
  this.PageNumber = 1;
  this.router.navigate(["users/in-active-users"], {
    queryParams: {
      pageNumber: this.PageNumber,
      pageSize: this.PageSize,
      // filterId: this.filterId,
      SortBy: this.direction,
      searchTerm: this.searchQuery.trim(),
    },
  });
  this.objmethod();
}
  // getInactiveUserList()
  // {
  //  //debugger;
  // //  this.showloader();
  //  this.isLoad = false;   
  //   this.userService.getAllInActiveUsersList().subscribe((res:any)=>{
  //     this.isLoad = false;
  //   //  this.hideloader();
  //     this.users = res.users;
  //   // this.users = res.list;
  //     console.log('list:', res)    
  //   })
  //   // (error) => {
  //   //   this.isLoad = false
  //   // });
  // }
  get vstatus(){
    return this.popupForm1.get("isActive");
    }

  edit(data: any, id: any) {
    this.showloader();
    this.showModal = true;
    this.isEdit = true;
    this.load = false;
    this.id = id;
    this.firstName = this.firstName;
    this.lastName = this.lastName;
    this.email = this.email;
    this.mobile = this.mobile;
    this.address1 = this.address1;
    this.address2 = this.address2;
    this.zipCode = this.zipCode;
    this.isActive = this.isActive;
    this.photoLocation = this.photoLocation;
    this.userService.getUserbyId(id)
      .subscribe((res: any) => {
        if (res) {
          this.firstName = res.firstName;
          this.lastName = res.lastName;
          this.email = res.email;
          this.mobile = res.mobile;
          this.address1 = res.address1;
          this.address2 = res.address2;
          this.zipCode = res.zipCode;
          this.isActive = res.isActive;
          this.photoLocation = res.photoLocation;
        }
      });
  }

  saveUserInactive()  
  {
    debugger;
  let item = {
    "id": this.id,
   // "isActive": this.isActive.value == "Yes" ? true : false
   "firstName": this.firstName,
   "lastName": this.lastName,
   "email": this.email,
   "mobile": this.mobile,
   "address1": this.address1,
   "address2":this.address2,
   "zipCode": this.zipCode,
   "photoLocation": this.photoLocation,   
  // "isActive":this.popupForm1.value == "true" ? false : true
 "isActive":this.isActive == "true" ? false : true
  }
  
  this.isActiveStatus = item;
  
  
  let obj = {
    id: this.id,
    isActive: this.isActive
    //firstName: this.firstName,
  }
  console.log('Updating user status with:', obj);
  this.userService.updateUserStatus(obj.id, this.isActiveStatus).subscribe(res => {
    // if (res) {
    //  // this.load = false;          
    //   this.firstName = res.firstName;
    //   this.lastName = res.lastName;
    //   this.email = res.email;
    //   this.zipCode = res.zipCode;
    //   this.isActive = res.isActive;
           
    // }  
    this.showModal = false;
    this.submitted = true;
    console.log('SAVE:', obj)
    console.log('SAVE:', obj)
    this.showSuccess();
 // this.getInactiveUserList();
 this.objmethod();
  this.getInActiveUserCount();
  this.popupForm1.reset();
  //this.toastr.success('Update successful!', 'Success');
  //this.showCreated();
  },
  // err => {
  //   console.error('Error updating user status:', err);
  // }
  )
  }
  getInActiveUserCount() { 
    this.userService.getInActiveUserCount().subscribe((res: any) => {    
      this.inactiveuserscount = res;  
      //this.objmethod();    
    })
  }
  hide(){
    this.showModal = false;
    this.popupForm1.reset();
  }
 
  public showloader() {
    this.loading = true;
  }
  public hideloader() {
    this.loading = false;
  }
  showSuccess(){
    this.toastr.success('User status Updated Successfully', 'Success')
  }
}
