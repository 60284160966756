
//import { Component, Renderer2 } from '@angular/core';
//import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
//import { Router } from '@angular/router';
//import { AuthService } from '../../../services/auth/auth.service';
//import { error } from 'console';
//import { HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
//import { catchError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Component, Host, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { first } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { error } from 'console';

import { CommonModule } from '@angular/common';
import { Renderer2, OnDestroy } from '@angular/core';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  //loginForm: FormGroup;
  
  form: FormGroup;

  isLoading = false;
  isError!: boolean;
  // email = new FormControl('', [Validators.required, Validators.email]);
  // password = new FormControl('', [Validators.required]);
  email = new FormControl();
  password = new FormControl();
  // loginForm = new FormGroup({
  //   email: new FormControl('', Validators.required,Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')),
  //   password: new FormControl("", Validators.required)
  // });

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private renderer: Renderer2) {
      this.form = this.formBuilder.group({
        email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        password: ['', [Validators.required]]
      });
    //     this.loginForm = this.formBuilder.group({
    //       email: ['',[Validators.required,
    //         Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')
    //       ]],
    //       password: ['', Validators.required]
    // //   password: new FormControl("", Validators.required)
    //     });
  }
  ngOnInit(): void {
   
    // this.form = this.formBuilder.group({
    //   email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    //   password: ['', [Validators.required]],
    //   rememberMe: [false]    });
  }

  // get vemail() {
  //   return this.loginForm.get("email");
  // }
  // get vpassword() {
  //   return this.loginForm.get("password");
  // }
  login() {
    //   debugger;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
      console.error("UnAuthorized");
    }
    if (this.email.invalid) {
      this.email.errors?.['email'] == true;
    }
    if (this.password.invalid) {
      this.password.errors?.['password'] == true;
    }
    this.isLoading = true;
    setTimeout(() => {
    }, 5000);
    this.authService.login(this.form.value)
      .pipe(
        catchError((error) => {
          this.isError = true;
          this.isLoading = false;
          //return throwError(error.message, error);
          return throwError(() => error.message, error);
        })
      ).subscribe(res => {
        localStorage.setItem("token", res.token);
        localStorage.setItem('isLoggedin', 'true');
        localStorage.setItem('username', this.form.value.email);
        // if (this.loginForm.value.rememberMe) {
        //   localStorage.setItem('email', this.loginForm.value.email);
        //   localStorage.setItem('password', this.loginForm.value.password);
        // } else {
        //   localStorage.removeItem('email');
        //   localStorage.removeItem('password');
        // }
        if (res) {
          this.router.navigateByUrl('/dashboard');
        }
      })

  }

}
