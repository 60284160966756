<div class="animated fadeIn">
  <div class="row">
    <div class="col-lg-12">
      <div class="card text-black bg-white mb-3">
        <div class="card-body">
          <h5 class="card-title">Active Users</h5>
          <div class="text-black display-4 text-end">
            <p>{{userscount}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="isLoad" class="animated fadeIn">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <span><i class="icon-user-following"></i> Users</span>
          </div>
          <div class="card-body">
            <div
              class="table-responsive{-sm|-md|-lg|-xl} table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl">
              <table class="table table-striped table-hover">
                <thead class="table-primary">
                  <tr>
                    <th>UserName</th>
                    <th>Email</th>
                    <th>Created Date</th>
                    <th>Modified Date
                    <th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="6" class="text-center mt-3">
                      <div class="sk-wandering-cubes">
                        <div class="sk-cube sk-cube1"></div>
                        <div class="sk-cube sk-cube2"></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="animated fadeIn" *ngIf="!isLoad  && isSearch == false  ">
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-3">
          <div class="card-header">
            <span><i class="fa fa-users"></i> Active Users </span>

          </div>
          <div class="card-body">
            <div class="row mb-3">
              <!-- <form class="col-md-5 offset-md-7">
                 <div class="input-group clear">
                  <span *ngIf="searchValue!=''">
                    <i class="fa fa-close" (click)="clear()"></i>
                  </span>
                  <input type="text" name="search" class="form-control" (keyup)="keyUp()" autocomplete="off"
                    value={{searchValue}} placeholder="Search by name, phone, email" (paste)="searchChange()" />
                  <div class="input-group-prepend">
                    <button type="submit" class="input-group-text btn btn-wave btn-light" (click)="searchByValue()">
                      <i class="fa fa-search"></i>
                    </button>
                    
                  </div>
                </div>  </form> -->
              <form class="col-lg-12 col-md-12 col-sm-12 input-group mb-4">
                <input class="form-control" type="text" name="search" (keyup)="keyEvent($event)"
                  (keydown)="keyEvent($event)" placeholder="Search by FirstName, LastName" autocomplete="off"
                  value={{searchQuery}}>
                <div class="input-group-prepend">
                  <button class="input-group-text btn btn-wave btn-light" type="submit"
                    [disabled]="searchQuery==''||searchQuery==undefined" (click)="searchByValue($event)"><i
                      class="fa fa-search"></i>
                  </button>
                </div>
                <div class="input-group-prepend">
                  <button (click)="handleClear()" class="input-group-text btn btn-wave btn-light"
                    [disabled]="searchQuery==''||searchQuery==undefined" (click)="searchByValue($event)"><i
                      class="fa fa-close"></i>
                  </button>
                </div>
              </form>
            </div>
            <div class="row">
              <div class="col-lg-10 col-md-7 col-sm-12">
                <pagination-controls previousLabel="Prev" nextLabel="Next" [responsive]="true"
                  (pageChange)="handlePageChange($event)"></pagination-controls>
              </div>
              <div class="col-lg-2 col-md-5 col-sm-12 pull-right">
                <span class="float-right">
                  Items Per Page
                  <select (change)="handlePageSizeChange($event)">
                    <option *ngFor="let size of pageSizes" [ngValue]="size" [selected]="size == PageSize">
                      {{ size }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
            <div
              class="table-responsive{-sm|-md|-lg|-xl} table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl">
              <table class="table table-striped table-hover">
                <thead class="table-primary">
                  <tr>
                    <th id="tname" (click)="onSort(1)" [class.asc]="direction==='asc'"
                      [class.desc]="direction === 'desc'" style="cursor: pointer;">UserName<span style="float:right">
                        <div
                          *ngIf="column  && (direction === 'desc' ||  direction === 'asc');then content else other_content">
                        </div>
                        <ng-template #content>
                          <i class="fa fa-sort-down" *ngIf="direction === 'desc'"></i>
                          <i Class="fa fa-sort-up" *ngIf="direction === 'asc'"></i>
                        </ng-template>
                        <ng-template #other_content>
                          <i *ngIf="sort" class="fa fa-sort"></i>
                        </ng-template>
                      </span></th>

                    <th>Email</th>
                    <th>Created Date</th>
                    <th>Modified Date
                    <th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of list | paginate:{ 
                    itemsPerPage: PageSize,
                    currentPage: PageNumber,
                    totalItems: totalItems
                    
                  }">
                    <td>{{data.firstName}} {{data.lastName}}</td>
                    <td>{{data.email}} </td>
                    <td>{{data.createdDate | date: 'dd/MM/yyyy'}} </td>
                    <td>{{data.updatedDate | date: 'dd/MM/yyyy'}}</td>
                    <td>
                    <td>
                      <button type="button" class="btn btn-primary btn-sm" (click)="edit(myModal,data.id)">View</button>
                    </td>

                  </tr>
                  <tr *ngIf="emptytable">
                    <td class="text-center mt-3" colspan="6">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-10 col-md-6 col-sm-12">
                <pagination-controls previousLabel="Prev" nextLabel="Next" [responsive]="true"
                  (pageChange)="handlePageChange($event)"></pagination-controls>
              </div>
              <div class="col-lg-2 col-md-6 col-sm-12 pull-right">
                <span class="float-right">
                  Items per Page
                  <select (change)="handlePageSizeChange($event)">
                    <option *ngFor="let size of pageSizes" [ngValue]="size" [selected]="size == PageSize">
                      {{ size }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
            <!-- <div class="pagi-respon">
              <div class="pagination mx-auto">
                <div class="row page">
                  <div class="div-sec">
                    <pagination-controls previousLabel="Prev" nextLabel="Next" [responsive]="true" [maxSize]="5"
                      class="pagination" >
                    </pagination-controls>
                  </div>
                  <div class="col mblesize">
                   
                    <select class="form-control" >
                      
                    
                    </select>
                  </div>
                </div>
              </div>
            </div> -->

          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="modal" id="myModal" [style.display]="showModal ? 'block' : 'none'">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">User Details</h4>
          <button type="button" class="btn-close" aria-label="Close" data-dismiss="modal" (click)="hide()">
          </button>
        </div>
        <form class="form-horizontal" [formGroup]="popupForm" (ngSubmit)="saveUser()" autocomplete="new-password">
          <div class="modal-body ">
            <div *ngIf="load && isEdit==true" style="margin-left: 200px; margin-top: 50px;">
              <div class="bm-loading"></div>
            </div>
            <div *ngIf="(!load && isEdit == true) || isEdit != true ">

              <div class="form-group mb-3 row">
                <label class=" control-label col-sm-3">First Name</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" value="{{this.firstName}}" disabled>
                </div>
              </div>
              <div class="form-group mb-3 row">
                <label class="control-label col-sm-3">Last Name</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" value="{{this.lastName}}" disabled>
                </div>
              </div>
              <div class="form-group mb-3 row">
                <label class="control-label col-sm-3">Email</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" value="{{this.email}}" disabled>
                </div>
              </div>
              <div class="form-group mb-3 row">
                <label class="control-label col-sm-3">Mobile</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" value="{{this.mobile}}" disabled>
                </div>
              </div>
              <div class="form-group mb-3 row">
                <label class="control-label col-sm-3">Address</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" value="{{this.address2}}" disabled>
                </div>
              </div>
              <div class="form-group mb-3 row">
                <label class="control-label col-sm-3">ZipCode</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" value="{{this.zipCode}}" disabled>
                </div>
              </div>
              <div class="form-group mb-3 row ">
                <label for="isActiveSelect " class="control-label col-sm-3">Status</label>
                <div class="col-sm-9">
                  <select class=" form-control form-control-sm form-select" id="isActiveSelect"
                    placeholder=".form-control-sm" formControlName="isActive">
                    <!-- <option value="null" selected disabled>Please select status</option> -->
                    <option class=" form-control" value="true" disabled>Active</option>
                    <option value="false">Inactive</option>
                  </select>
                  <span *ngIf="vstatus && vstatus.invalid && vstatus.touched" style="color: red;">Please select
                    status</span>
                </div>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-outline-info" [disabled]="popupForm.invalid">Save</button>
            <button type="button" (click)="hide()" class="btn btn btn-outline-danger"
              data-dismiss="modal">Close</button>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>

  <!--New mahial-->