import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MemoriesService } from "../../../services/memories/memories.service";
@Component({
  selector: 'app-memories',
  templateUrl: './memories.component.html',
  styleUrls: ['./memories.component.scss']
})
export class MemoriesComponent implements OnInit {
  isLoad = true;
memories : any[] =[];
id:any;

userId: any;
firstName: string | undefined;
memoryCount: any;
memories1 =[{
  "user":{
            "firstName": "Adam",
            "lastName": "Moreira",
  },
  "profile":{
    "profileType": "Person",
    "firstName": "RAja RAm",
    "lastName": "Mohan Roy",
  }
  
}]

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,

    private httpClient: HttpClient,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    public vcRef: ViewContainerRef,
    //private toastr: ToastrService,
    // private navigationItems: NavigationItems,
    // private toastr: ToastrService,
    private memoriesService: MemoriesService
  ) { }

  ngOnInit(): void {
   // this.userId = + this.activatedRouter.snapshot.paramMap.get('userId'); 
    this.getMemoryList();
    this.GetAllProfilesCount();
  }
  getMemoryList() {   
   // debugger;
    this.isLoad = false;
    this.memoriesService.getMemoriesList().subscribe((res: any) => {  
      //this.memories = res.memories || res;     
      this.memories = res;     
     
    })   
  }
  hideloader() {
    throw new Error('Method not implemented.');
  }
  showloader() {
    throw new Error('Method not implemented.');
  }
  GetAllProfilesCount(){
    this.memoriesService.GetAllProfilesCount().subscribe((res: any) => {    
      this.memoryCount = res;  
      console.log('memoriesCount:', res.activeCount)
  })
}
}
