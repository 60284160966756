<!-- <div class="bg-light min-vh-100 d-flex flex-row align-items-center">
  <c-container>
    <c-row class="justify-content-center">
      <c-col md="8">
        <c-card-group>
          <c-card class="p-4">
            <c-card-body>
              <form cForm>
                <h1>Login</h1>
                <p class="text-medium-emphasis">Sign In to your account</p>
                <c-input-group class="mb-3">
                  <span cInputGroupText>
                    <svg cIcon name="cilUser"></svg>
                  </span>
                  <input autoComplete="username" cFormControl placeholder="Username" />
                </c-input-group>
                <c-input-group class="mb-4">
                  <span cInputGroupText>
                    <svg cIcon name="cilLockLocked"></svg>
                  </span>
                  <input
                    autoComplete="current-password"
                    cFormControl
                    placeholder="Password"
                    type="password"
                  />
                </c-input-group>
                <c-row>
                  <c-col xs="6">
                    <button cButton class="px-4" color="primary">
                      Login
                    </button>
                  </c-col>
                  <c-col class="text-right" xs="6">
                    <button cButton class="px-0" color="link">
                      Forgot password?
                    </button>
                  </c-col>
                </c-row>
              </form>
            </c-card-body>
          </c-card>
          <c-card [ngStyle]="{'width.%': 44}" class="text-white bg-primary py-5">
            <c-card-body class="text-center">
              <div>
                <h2>Sign up</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <button [active]="true" cButton class="mt-3" color="primary" routerLink="/register">
                  Register Now!
                </button>
              </div>
            </c-card-body>
          </c-card>
        </c-card-group>
      </c-col>
    </c-row>
  </c-container>
</div> -->
<!-- <section class="h-100 gradient-form" style="background-color: #eee;">
  <div class="container py-5 h-100  " style="background: pink;">
    <div class="row d-flex justify-content-center align-items-center h-100 ">
      <div class="col-xl-10">
        <div class="card rounded-3 text-black logContainer" style="background: red;">
          <div class="row g-0" style="background: green;">
            <div class="col-lg-6">
              <div class="card-body p-md-5 mx-md-4">

                <div class="text-center">
                  <img src="assets/logo/ImmortalLogo1.png"
                    style="width: 185px;" alt="logo">
                </div>
                <div class="text-center">
                  <img src="assets/logo/immortal_black_logo.png"
                    style="width: 185px;" alt="logo">
                </div>
                <form>
                  <p>Please login to your account</p>

                  <div class="form-outline mb-4">
                    <label class="form-label" for="form2Example11">Username</label>
                    <input type="email" id="form2Example11" class="form-control"
                      placeholder="Phone number or email address" />
                  </div>

                  <div class="form-outline mb-4">
                    <label class="form-label" for="form2Example22">Password</label>
                    <input type="password" id="form2Example22" class="form-control" />
                  </div>

                  <div class="form-outline mb-4">
                    <label>
                      <input type="checkbox" formControlName="rememberMe"> Remember Me
                    </label>
                  </div>
                  <div class="text-center pt-1 mb-5 pb-1">
                    <button class="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3" type="button">Log
                      in</button>

                  </div>



                </form>

              </div>
            </div>
            <div class="col-lg-6 d-flex align-items-center gradient-custom-2">
              <div class="text-white px-3 py-4 p-md-5 mx-md-4">
                <img src="assets/logo/gran4.jpg"
                    style="" alt="logo">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<div class="bg-light min-vh-100 d-flex flex-row align-items-center bg-color">
  <div class = "container h-70 d-inline-block bg-color">
    <div class="row justify-content-center bg-color">
      <!-- <div class= "container "> -->
        <!-- <div class="container row"> -->
          <div class="card col-lg-6 bg-color my-auto card-block d-flex" style="background: #f5f6f7;">
            <div class="card-body align-items-center  justify-content-center">
              <!-- <div class="text-center">
                <img src="assets/logo/ImmortalLogo1.png"
                  style="width: 185px;" alt="logo">
              </div> -->
              <div class="">

              <form [formGroup]="form" >
                <div class="text-center">
                  <img src="assets/logo/immortal_white_logo.png"
                    style="width: 185px;" alt="logo">
                </div>

                <div class="form-outline mb-4">
                  <label class="form-label font-color" for="form2Example11">Email</label>
                  <input type="email" id="form2Example11" class="form-control" formControlName="email"
                    placeholder="email@email.com" aria-label="email" />
                   <!-- <span *ngIf="vemail && vemail.invalid && vemail.touched" class="err-span">* Enter valid email</span>  -->
                   <!-- <span *ngIf="email && email.invalid && email.touched" class="err-span">* Enter valid email</span>  -->
                    <div *ngIf="form.get('email')?.touched && form.get('email')?.invalid" class="err-span">
                      <div *ngIf="form.get('email')?.errors?.['required']">Email is required.</div>
                      <div *ngIf="form.get('email')?.errors?.['pattern']">Invalid email format.</div>
                    </div>
                </div>

                <div class="form-outline mb-4">
                  <label class="form-label font-color" for="form2Example22">Password</label>
                  <input type="password" id="form2Example22" class="form-control" formControlName="password" placeholder="Password"/>
                  <!-- <span *ngIf="vpassword && vpassword.invalid && vpassword.touched" class="err-span">* Enter valid password</span> -->
                  <div *ngIf="form.get('password')?.touched && form.get('password')?.invalid" class="err-span">
                    <div *ngIf="form.get('password')?.errors?.['required']">Password is required.</div>
                  </div>
                </div>

                <!-- <div class="form-outline mb-4">
                  <label>
                    <input type="checkbox" formControlName="rememberMe"> Remember Me
                  </label>
                </div> -->
                <div class="container w-50 my-4">
                  <div class="row">
                  <button class="btn btn-outline-primary radius-20" type="submit" (click)="login()" [disabled]="form.invalid" [ngClass]="{
                    'btn-enabled': !form.invalid,
                    'btn-disabled': form.invalid
                  }">Log
                    in</button>
                    </div>
                    </div>
<!--
                    <div class="container rounded">
                      <div class="row">
                        <div class=" my-auto mycentered-text">
                          <button class="btn btn-primary mx-10"> Login </button>
                        </div>
                      </div>
                    </div> -->

              </form>
            </div>
            </div>
          </div>
          <div  class="card col-lg-6 bg-color bg-image" style="background: #f5f6f7;">


                <!-- <img class="card-img-top card-img-top1" src="assets/logo/gran4.jpg" alt="Card image cap"> -->
                <img class="w-100" src="assets/logo/gran4.jpg" alt="Card image cap">
                <div class="card-img-overlay text-white" style="background-color: rgba(0, 0, 0, 0.5)">
                   <div class="">
                <img src="assets/logo/ImmortalLogo1.png"
                  style="width: 100px;" alt="logo">
              </div> 
                  <h1 style="margin-left: 10px;">Gather,</h1>
                  <h1 style="margin-left: 10px;">Share,</h1>
                  <h1 style="margin-left: 10px;">Cherish.</h1>
                </div>

          </div>
        <!-- </div> -->
      <!-- </div> -->
    </div>
  </div>
</div>