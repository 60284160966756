import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { resolve } from 'path';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private API_URL = environment.API_URL;

  constructor(private http: HttpClient) { }

  login(params: any) {
   //  debugger;
     // alert('login');
     const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
     return this.http.post<any>(`${this.API_URL}/Auth/login`, params, { headers: headers })
       .pipe(map(user => {
         return user;
       }));
   }
}
