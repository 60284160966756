import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { UsersRoutingModule } from './users-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { InActiveUsersComponent } from '../users/in-active-users/in-active-users.component';
import { AllMemoriesComponent } from '../Memories/all-memories/all-memories.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MemoriesModule } from '../Memories/memories.module';
import { PagesModule } from '../pages/pages.module';

@NgModule({
  declarations: [
    InActiveUsersComponent,
  
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    NgxPaginationModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PagesModule
    
  ]
})
export class UsersModule { }
