import { Component } from '@angular/core';

import { navItems } from './_nav';
import 'bootstrap/dist/css/bootstrap.min.css';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent {

  public navItems = navItems;

  public perfectScrollbarConfig = {
    suppressScrollX: true,
  };

  // public toasterconfig: ToasterConfig =
  // new ToasterConfig({
  //   tapToDismiss: true,
  //   timeout: 1000
  // });

  constructor() {}
}
